import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Timeline } from 'react-twitter-widgets'


import "../../components/scss/app.scss"

const Blog = () => (
    <Layout>
    <SEO title="Blog | Articles About Cosmetic Dentistry Every Month | Smile Stylist" 
    description="Keep up to date with the various trends in cosmetic dentistry, porcelain veneers and dental implants with The Smile Stylist Cosmetic Dentistry Blog." 
    keywords="cosmetic dentistry,porcelain veneers,teeth bonding" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Blog</h1>
            <h2>Articles about oral health every month</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight" id="blog">
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/The Smile Stylist - Confidence in smile Cirlce.png" )} className="bl__imgb" alt="Smile Makeover Confidence"  />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/the-confidence-behind-a-smile">The confidence behind a smile makeover</a>
                </h4>
                <p>Humankind is one that is deeply dependent on subconscious needs. This could be the more classic need to be fed, or have a drink, or for the sake of this article...
                  <span><a href="/blog/the-confidence-behind-a-smile"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/circle blog - clear correct .png" )} className="bl__imgb" alt="Invisible braces"  />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/why-more-people-are-choosing-clear-correct-invisible-braces">Why more people are choosing Clear Correct Invisible Braces</a>
                </h4>
                <p>Braces have infamously been connected with bulky, and unattractive hunks of metal in a patients mouth. However, with present day technology and improvements in dental procedures...
                  <span><a href="/blog/why-more-people-are-choosing-clear-correct-invisible-braces"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/Styling-your-Teeth-To-your-Smile-Circle-image.png" )} className="bl__imgb" alt="Style Your Teeth"  />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/styling-your-teeth-to-your-smile">Styling your teeth to your smile</a>
                </h4>
                <p>Have you even thought about the perfect smile for your face? What would you change? I am sure you are thinking of a few things that you have seen that you would love to have to work with...
                  <span><a href="/blog/styling-your-teeth-to-your-smile"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/Why-Men-are-getting-cosmetic-dentistry Blog-Circle-image.png" )} className="bl__imgb" alt="Men Cosmetic Dental"  />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/why-men-are-getting-more-cosmetic-dental">Why men are getting more cosmetic dental</a>
                </h4>
                <p>A smile is where a relationship starts, whether it is personal or professional, and more than even have realized that their dental looks are important. We live in an ultra competitive world...
                  <span><a href="/blog/why-men-are-getting-more-cosmetic-dental"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/Bachelorette-Blog-Circle-image.png" )} className="bl__imgb" alt="The Best Bachelorette Smile"  />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/who-has-the-best-smiles-on-the-bachelorette">Who has the best smiles on the bachelorette</a>
                </h4>
                <p>Season 14 of the Bachelorette is just getting started, and the team at The Smile Stylist has taken it upon themselves to craft this year’s list of the top smiles. Could it be Chris Harrison…
                  <span><a href="/blog/who-has-the-best-smiles-on-the-bachelorette"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/blog-oral-dog-cover.png" )} className="bl__imgb" alt="Dog's Oral Health"  />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/essential-things-about-your-dogs-oral-health">Everything you need to know about your dog's oral health.</a>
                </h4>
                <p>When it comes to our four-legged babies, their dental health seems to be something that even the best dog moms and dads often forget to check, or maintain.
                  <span><a href="/blog/essential-things-about-your-dogs-oral-health"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/oral-cancer-cover.png" )} className="bl__imgc" alt="Oral Cancer Detection" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/5-ways-to-detect-oral-cancer">5 Ways to detect oral cancer at home</a>
                </h4>
                <p>As we have heard time and again, the best defence against cancer is detecting it early on. This is no different when it comes to mouth and throat cancer, and we want to ensure that you are not affected by this life-changing disease.
                  <span><a href="/blog/5-ways-to-detect-oral-cancer"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/smmbl__gurl.png" )}  className="bl__imgc" alt="Smile Makeover For Better Life" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/smile-makeover-for-better-life">Smile Makeover for a better life? </a>
                </h4>
                <p>What do you think the first thing people notice about you? Is it your height, your weight? Well according to some of the latest research it is, in fact, your smile.
                  <span><a href="/blog/smile-makeover-for-better-life"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/bmi.png" )} alt="The Bachelor Smile" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/best-smile-on-bachelor">Best Smiles on The Bachelor</a>
                </h4>
                <p>With “The Bachelor” wrapping up this week, there is not much left to do but forget about what happened and try to smile!! Did Arie actually pull a “Josh McDaniels” and back out of his engagement only to go chase another woman? Did he actually wait to break up with her until the cameras were present? UNBELIEVEABLE. All we can do now is try to forget about it, put a nice smile on our faces and try to move forward.
                  <span><a href="/blog/best-smile-on-bachelor"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/_blog/pics/ftop1.jpg" )} alt="Secretes Of Photogenic Smile" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/secrets-of-a-photogenic-smile">Five Top Secrets of the Most Photogenic Smiles </a>
                  <br />
                  <small>Are you doing these things before the camera clicks?</small>
                </h4>
                <p>Are you putting your most attractive and inviting smile forward in pictures? The truth is, not everyone knows what to do or how to pose in order to achieve the most photogenic smile possible. Luckily, our Smile Stylist wants to share five top secrets to showing your best smile in photos!<br />
                  <span><a href="/blog/secrets-of-a-photogenic-smile"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/mb1.png" )} alt="Hollywood Smile Game" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/name-that-hollywood-smile">Game: Name That Hollywood Smile!</a>
                  <br />
                  <small>Whose Stunning Teeth Are Those?!</small>
                </h4>
                <p>
                  It’s time to take a guess at these stunning Hollywood smiles transformed by the magic of cosmetic dentistry. How well do you know these picture-perfect grins? Let’s find out.<br />
                  <span><a href="/blog/name-that-hollywood-smile"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/_blog/pics/Kristina.png" )} alt="Bachelors In Paradise Smiles" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/bachelor-paradise-smiles">Our Top Six Bachelor in Paradise Smiles!</a>
                  <br />
                  <small>Do the BIP Stars with Better Smiles get a Better Shot at Love?...</small>
                </h4>
                <p>
                  Any Bachelor in Paradise fans out there?! If so, this one’s for you. Let’s take a look at six of the top BIP contender smiles that have caught our attention.<br />
                  <span><a href="/blog/bachelor-paradise-smiles"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/_blog/pics/july2017/july2017-sm.jpg" )} alt="Change Your Life" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/better-smile-better-life">Four Ways a Smile Makeover Can Change Your Life</a>
                  <br />
                  <small>What are you missing out on?</small>
                </h4>
                <p>
                  People who have transformed their smiles with a smile makeover notice just how dramatically their lives have changed after their procedure. A great smile not only enhances a person’s look, but it also has the power to open you up to new and exciting life experiences.<br />
                  <span><a href="/blog/better-smile-better-life"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/_blog/pics/june2017-sm.jpg" )} alt="Online Dating Life" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/your-smile-and-dating">Is Your Smile Compromising Your Online Dating Life?</a>
                  <br />
                  <small>Why your teeth are #1 when it comes to the game of love…</small>
                </h4>
                <p>
                  Did you know? A person’s smile can either make or break a love connection… and there are numerous studies that have been able to back this up. What’s your smile doing for your dating and love life? Here are four reasons why an attractive smile is proven to make you a winner in love.
                  <br />
                  <span><a href="/blog/your-smile-and-dating"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
            <div className="blog-art">
              <div className="img-blog img-thumbnail">
                <img src={ require("../../images/desktop/_blog/pics/may2017-sm.jpg" )} alt="Celebrity Smile" />
              </div>
              <div className="text-blog">
                <h4>
                  <a href="/blog/achieve-a-celebrity-smile">How Can I Achieve a Celebrity Smile?</a><br />
                  <small>Our Smile Stylist Considers These Three Things</small>
                </h4>
                <p>
                  Celebrities seem to have the most stunning smiles, don’t they? Well, there’s a reason for this – and Dr. Kaloti knows just what goes into crafting that perfect Hollywood smile. Is your smile beautiful enough to turn heads? Here are three things our Smile Stylist considers to help patients achieve that perfect celebrity grin.<br />
                  <span><a href="/blog/achieve-a-celebrity-smile"> Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4 blog-right  wow animated zoomInRight" data-wow-delay="0.6s">
          <Timeline
    dataSource={{
      sourceType: 'profile',
      screenName: 'smilestylistto'
    }}
    options={{
      username: 'SmileStylistTo',
      height: '500'
    }}
  />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default Blog